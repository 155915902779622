import React from 'react'
import myself from '../assets/myself.png'
import { FaGithub, FaLinkedin } from 'react-icons/fa'
import { TypeAnimation } from 'react-type-animation'
import { motion } from 'framer-motion' 
import { fadeIn } from '../variants' 
import { Tilt } from 'react-tilt' 
import ShimmerText from './Shimmer' 

const Banner = () => { 
  const pdfUrl = process.env.REACT_APP_PDF_URL;
  console.log(pdfUrl) 
  return ( 
    <section className='pt-[70px] min-h-[85vh] lg:min-h-[78vh] flex items-center' id='home'> 
      <div className="container mx-auto">
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'> 
          <div className='flex-1 text-center font-secondary lg:text-left'>
            <motion.div variants={fadeIn('right', 0.1)} initial="hidden" whileInView={'show'} viewport={{ once: true, amount: 0.7 }} className='mb-4'>
              <span className='lg:text-[25px]'>Hello, my name is</span>
            </motion.div> 
            <motion.h1 variants={fadeIn('right', 0.2)} initial="hidden" whileInView={'show'} viewport={{ once: true, amount: 0.7 }} className="mb-2">
              <span className='text-[55px] lg:text-[75px] font-bold leading-[0.8]'>TRUNG TRAN</span>  
            </motion.h1>
            <motion.div variants={fadeIn('right', 0.3)} initial="hidden" whileInView={'show'} viewport={{ once: true, amount: 0.7 }} className='mb-10 text-[36px] lg:text-[50px] font-secondary font-semibold uppercase leading-[1]'>
              <span className='font-normal'>I am a </span>
              <TypeAnimation sequence={['Software Engineer!', 3300, 'Lifelong Learner!', 2000, 'Poke Bowl Lover!', 2000,]} speed={50} className="text-yellow-500" wrapper='span' repeat={Infinity} />
            </motion.div>
            <motion.div variants={fadeIn('right', 0.4)} initial="hidden" whileInView={'show'} viewport={{ once: true, amount: 0.7 }} className='mb-6 text-[20px] lg:text-[25px] font-secondary font-normal  leading-[0]'>
              Undergrad Software Engineer 
            </motion.div>
            <motion.div variants={fadeIn('right', 0.4)} initial="hidden" whileInView={'show'} viewport={{ once: true, amount: 0.7 }} className='mb-6 text-[20px] lg:text-[25px] font-secondary font-normal  leading-[0]'>
              San Jose State University
            </motion.div>
            <motion.div variants={fadeIn('right', 0.4)} initial="hidden" whileInView={'show'} viewport={{ once: true, amount: 0.7 }} className="space-x-1 mb-6 text-[20px] lg:text-[25px] font-secondary uppercase font-normal leading-[1.5]">
              <div className="text-[27px]">
                <ShimmerText text="I love building cool things!" />
              </div>
            </motion.div>
            <motion.div variants={fadeIn('up', 0.4)} initial="hidden" whileInView={'show'} viewport={{ once: true, amount: 0.7 }} className="flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0 lg:justify-left">
            <a href='https://github.com/trungtran1234' target='_blank' rel="noopener noreferrer" className='text-3xl hover:text-yellow-500 hover:scale-150 duration-500'>
                <FaGithub />
              </a> 
              <a href='https://www.linkedin.com/in/trung-tran1234/' target="_blank" rel="noopener noreferrer" className='text-3xl hover:text-yellow-500 hover:scale-150 duration-500'>
                <FaLinkedin /> 
              </a>

            </motion.div> 
          </div>
          <motion.div variants={fadeIn('left', 0.3)} initial="hidden" whileInView={'show'} viewport={{ once: true, amount: 0.7 }} className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[400px]'>
          <motion.div
      className="w-37.5 h-37.5 rounded-full flex justify-center items-center" onClick={(e) => e.preventDefault()}>
      <motion.img
        src={myself}
        alt="me"
        initial={{ scale: 0.95 }}
        animate={{
          scale: [0.95, 1.1, 0.95],
          transition: {
            repeat: Infinity,
            duration: 8,
            ease: "easeInOut"
          }
        }}
        className="w-full h-auto"
      />
    </motion.div>
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default Banner